class RadioUI
{

    static PreviousStats    = null;
    static CurrentStats     = {
        Song: {
            FullName:   "None"
        },
        Presenter: {
            Presenter: "None",
            ShowName: "None"
        }
    };
    static UIUpdater            = null;
    static CurrentQueue         = {};
    static RecentlyPlayed       = {};
    static RatingID             = 0;
    static DoneRequestedSong    = false;

    static statsUpdated(stats)
    {

        RadioUI.PreviousStats   = RadioUI.CurrentStats;
        RadioUI.CurrentStats    = stats;

        if(RadioUI.UIUpdater == null)
        {
            console.log("Creating UI updater");

            RadioUI.UIUpdater = setInterval(function() {

                RadioUI.updateUI();

            }, 1000);

            RadioUI.updateUI();
        }

        //Main Stats
        if(RadioUI.CurrentStats.Presenter.Presenter != RadioUI.PreviousStats.Presenter.Presenter)
        {
            $("#stat_presenter").text(stats.Presenter.ShowName);
            $("#stat_presenter_pro_image").attr("src", stats.Presenter.PhotoPro);
            $("#stat_presenter_normal_image").attr("src", stats.Presenter.PhotoNormal);
        }

        if(RadioUI.CurrentStats.Song.FullName != RadioUI.PreviousStats.Song.FullName)
        {
            $("#stat_song").text(stats.Song.FullName);
        }

        if($("#radio_home").length > 0)
        {
            if(RadioUI.CurrentStats.Song.FullName != RadioUI.PreviousStats.Song.FullName)
            {
                console.log(`${RadioUI.PreviousStats.Song.FullName} => ${RadioUI.CurrentStats.Song.FullName}`);

                $("#np_album_art").attr("src", stats.Song.AlbumArtURL);
                $("#np_song_artist").text(stats.Song.Artist);
                $("#np_song_title").text(stats.Song.Title);

                RadioUI.updateRequestedBy();

            }
        }

    }

    static updateUI()
    {

        var stats = this.CurrentStats;

        //Homepage stats
        if($("#radio_home").length > 0)
        {

            if(stats.Song.HasDuration)
            {
                var duration    = stats.Song.Duration;
                var startTime   = new Date(stats.Song.StartedAt);
                var endTime     = new Date(stats.Song.EndedAt);

                RadioUI.setDuration(startTime, endTime, duration);
            }
            else
            {
                RadioUI.setDuration(new Date(), new Date(), 0);
            }

            //Updating ratings
            Object.keys(RadioUI.CurrentStats.Song.Ratings).forEach((key) => {

                var ratingID = RadioData.RatingTranslate[key];

                $(`#stat_rating_value_${ratingID}`).text(RadioUI.CurrentStats.Song.Ratings[key]);

            });

            RadioUI.updateComingUp();

        }
    }

    static updateRequestedBy()
    {
        if(RadioUI.CurrentStats.Song.IsRequested)
        {

            var details = RadioUI.CurrentStats.Song.RequestDetails;
            var source  = RadioData.RequestSources[details.Source];

            $("#requested_by_container").removeClass("d-none");
            $("#requester_avatar").attr("src", details.Client.AvatarURL);
            $("#requester_name").text(details.Client.DisplayName);
            $("#requester_source").html(`<i class="${source.icon} me-2"></i> ${source.name}`);

            if(details.HasDedication)
            {
                $("#request_dedication").removeClass("d-none");

                $("#dedication_text").text(details.Dedication);

            }
            else
            {
                $("#request_dedication").addClass("d-none");
            }

            if(details.Source == 2 && details.Server != null)
            {

                var game = RadioData.Games[details.Server.GameID];

                $("#requester_server").removeClass("d-none");
                $("#requester_server").html(`<img src="${game.icons.small}" class="icon-sm" alt="${game.name}"> ${details.Server.Name}`);

            }
            else
            {
                $("#requester_server").addClass("d-none");
            }

        }
        else
        {
            $("#requested_by_container").addClass("d-none");
        }
    }

    static updateRecentlyPlayed(list)
    {
        var container = $("#recent_list_home");

        if(container.length > 0)
        {

            container.empty();

            var i = 0;

            list.forEach(element => {

                if(i >= 6)
                    return;

                container.append(`
                <div class="d-flex flex-row gap-3">

                    <div class="album-art flex-shrink-0"><img src="${element.AlbumArtURL}" alt="${element.FullName}"></div>
                    <div class="d-flex flex-column gap-1 justify-content-center flex-grow-1 flex-shrink-1">

                        <div class="fs-5">${RadioUtils.htmlEncode(element.Artist)}</div>
                        <div class="fs-6">${RadioUtils.htmlEncode(element.Title)}</div>

                    </div>
                    <div class="d-flex flex-column justify-content-center text-muted">
                        <div>${RadioUI.recentlyPlayedTimeHTML(element, (i == 0))}</div>
                    </div>

                </div>
                `);
                
                i++;

            });

        }

        var pageContainer = $("#recently_played_page");

        if(pageContainer.length > 0)
        {
            pageContainer.empty();

            list.forEach(element => {

                var time = moment(element.PlayedAt);

                pageContainer.append(`
                <div class="recent-item">

                    <div class="recent-item-bg" style="background-image:url('${element.AlbumArtURL}');"></div>

                    <div class="recent-item-bars">
                        <div class="bar">
                            <div><i class="fa-solid fa-user-music fa-fw fa-lg"></i> ${RadioUtils.htmlEncode(element.Artist)}</div>
                            <div><i class="fa-solid fa-music fa-fw fa-lg"></i> ${RadioUtils.htmlEncode(element.Title)}</div>
                        </div>

                        <div class="flex-grow-1 flex-shrink-1"></div>

                        <div class="bar">
                            <div><i class="fa-solid fa-album-collection fa-fw fa-lg"></i> ${element.Album}</div>
                            <div><i class="fa-solid fa-clock fa-fw fa-lg"></i> ${time.fromNow()}</div>
                        </div>
                    </div>

                </div>
                `);

            });

        }

    }

    static recentlyPlayedTimeHTML(item, first)
    {
        if(first == true)
            return `<span class="badge text-bg-success">Now Playing</span>`;

        var time = moment(item.PlayedAt);

        return `<i class="fa-duotone fa-clock"></i> ${time.fromNow()}`;

    }

    static updateComingUp()
    {

        if(RadioUI.CurrentStats.Presenter.Presenter != "AutoDJ")
        {
            $("#coming_up_box").addClass("d-none");
            return;
        }

        $("#coming_up_box").removeClass("d-none");

        var newItems = {};

        Object.keys(RadioUI.CurrentStats.ComingUp).forEach((key) => {

            newItems[key] = RadioUI.CurrentStats.ComingUp[key];

        });

        Object.keys(RadioUI.CurrentQueue).forEach((key) => {

            if(newItems[key] == null)
            {
                console.log(`Removing item from current queue: ${key}`);
                delete RadioUI.CurrentQueue[key];
            }

        });

        Object.keys(newItems).forEach((key) => {

            if(RadioUI.CurrentQueue[key] != null)
                return;

            RadioUI.CurrentQueue[key] = newItems[key];

            console.log(`Adding item to queue: ${key}`);

        });

        //Update the UI
        $("#coming_up_list_home").children().each(function() {

            var qID = $(this).attr("qid");

            if(qID == null || qID == "")
                return;

            var iQID = parseInt(qID);

            if(RadioUI.CurrentQueue[iQID] == null)
            {
                $(this).remove();
                console.log(`Removing UI item for ${iQID}`);
            }

        });

        //Add any new items
        Object.keys(RadioUI.CurrentQueue).forEach((key) => {

            var obj = $(`#coming_up_item_${key}`);
            
            if(obj.length > 0)
                return;

            var item = RadioUI.CurrentQueue[key];

            $("#coming_up_list_home").append(`
            <div class="d-flex flex-row gap-3" qid="${key}" id="coming_up_item_${key}">

                <div class="album-art flex-shrink-0 flex-grow-0"><img src="${item.albumart}" alt="Album Art"></div>
                <div class="d-flex flex-column gap-1 justify-content-center flex-grow-1 flex-shrink-1">

                    <div class="fs-5">${item.artist}</div>

                </div>
                <div class="d-flex flex-column justify-content-center text-muted">
                    ${RadioUI.getRequestedHTML(item)}
                </div>

            </div>
            `);

            console.log(`Adding UI Queue item for ${key}`);

        });

    }

    static getRequestedHTML(item)
    {
        if(item.requested)
            return `<span class="badge text-bg-primary fs-6"><i class="fa-solid fa-comment-music"></i> Requested</span>`;

        return ``;
    }

    static setDuration(startTime, endTime, duration)
    {
        var currentDate = new Date();
        var currentTS   = Math.round(currentDate.getTime() / 1000);
        var startTS     = Math.round(startTime.getTime() / 1000);
        var endTS       = Math.round(endTime.getTime() / 1000);
        var curProgress = currentTS - startTS;
        var curPercent  = curProgress / duration;
        var clmpPercent = Math.max(0, Math.min(curPercent * 100, 100));

        if(duration == 0)
            clmpPercent = 0;

        
        var timeCurrent = (duration > 0) ? RadioUtils.fancyTimeFormat(curProgress) : RadioUtils.fancyTimeFormat(0);
        var timeMax     = RadioUtils.fancyTimeFormat(Math.round(duration));

        $("#np_time_current").text(timeCurrent);
        $("#np_time_max").text(timeMax);
        $("#np_progress_bar").css('width', `${clmpPercent}%`);
        $("#np_progress_ball").css('left', `calc(${clmpPercent}% - 2px)`);

    }

    static userRating(data)
    {
        var type    = RadioData.Ratings[data.Rating];
        var clType  = RadioData.ClientTypes[data.User.Type];

        if(type == null)
            return;

        if(clType == null)
            return;

        RadioUI.RatingID++;

        var name = `rating_fly_${RadioUI.RatingID}`;

        $("body").append(`
        <div class="user-rating" id="${name}">

            <div class="flex-grow-0 flex-shrink-0">
                <img src="${data.User.AvatarURL}" class="avatar-md border rounded-circle border-primary border-2" alt="${data.User.DisplayName}">
            </div>

            <div class="d-flex flex-column gap-1 flex-grow-1 flex-shrink-1">

                <div class="d-flex flex-row gap-1 flex-grow-0 flex-shrink-0 align-items-center fs-5">
                    <i class="${type.icon}"></i>
                    <div>${type.name_plural}</div>
                </div>
                <div class="fs-4">${data.User.DisplayName}</div>
                <div class="fs-6"><i class="${clType.Icon}"></i> ${clType.Text}</div>

            </div>

        </div>
        `);

        var elem    = $(`#${name}`);
        var offset  = window.scrollY + window.innerHeight;

        elem.css('top', `${offset}px`);

        elem.animate({
            top: '-400px',
        }, 3500, function() {

            elem.remove();

        });

    }

    static setLoading(bShow)
    {
        var loader = $(".radio-load-overlay");

        if(bShow)
            loader.removeClass("d-none");
        else
            loader.addClass("d-none");

    }

    static loadConnecting()
    {
        var connecting      = $("#load-overlay-loading");
        var reconnecting    = $("#load-overlay-reconnecting");
        var error           = $("#load-overlay-error");

        connecting.removeClass("d-none");
        connecting.addClass("d-block");

        reconnecting.removeClass("d-block");
        reconnecting.addClass("d-none");

        error.removeClass("d-block");
        error.addClass("d-none");

    }

    static loadReconnect()
    {
        var connecting      = $("#load-overlay-loading");
        var reconnecting    = $("#load-overlay-reconnecting");
        var error           = $("#load-overlay-error");

        connecting.removeClass("d-block");
        connecting.addClass("d-none");

        reconnecting.removeClass("d-none");
        reconnecting.addClass("d-block");

        error.removeClass("d-block");
        error.addClass("d-none");
    }

    static loadError(err)
    {
        var connecting      = $("#load-overlay-loading");
        var reconnecting    = $("#load-overlay-reconnecting");
        var error           = $("#load-overlay-error");

        connecting.removeClass("d-block");
        connecting.addClass("d-none");

        reconnecting.removeClass("d-block");
        reconnecting.addClass("d-none");

        error.removeClass("d-none");
        error.addClass("d-block");

        $("#load-error-text").text(err);
    }

    static openWebPlayer()
    {
        window.open(route('front.radio.listen.webplayer'), "_blank", "height=950,location=no,menubar=no,resizable=no,scrollbars=no,status=no,toolbar=no,width=500");
    }

}

window.RadioUI = RadioUI;